import React from 'react'

import './style.css'

const Footer = () => (
	<footer className="footer">
		Copyright © {new Date().getFullYear()}, Built with{` `}
		<a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org/">Gatsby</a>
	</footer>
)

export default Footer
