import React from 'react'

import './style.css'

class ScrollIndicator extends React.Component {
	constructor(props) {
		super(props)

		this.scrollHandle = this.scrollHandle.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.scrollHandle)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scrollHandle)
	}

	scrollHandle() {
		let winScroll = document.body.scrollTop || document.documentElement.scrollTop
		let height = document.documentElement.scrollHeight - document.documentElement.clientHeight
		let scrolled = (winScroll / height) * 100
		this.refs.progress_bar.style.width = `${scrolled}%`
	}

	render() {
		return <div className="scroll-indicator">
			<div ref="progress_bar" className="progress-bar"></div>
		</div>
	}
}

export default ScrollIndicator
