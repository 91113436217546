import React from 'react'

import './style.css'

class BackToTop extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			show: false
		}

		this.timer = undefined
		this.scrollHandle = this.scrollHandle.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.scrollHandle)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scrollHandle)
	}

	scrollHandle() {
		clearTimeout(this.timer)
		this.timer = setTimeout(() => {
			this.setState({
				show: document.body.scrollTop > 700 || document.documentElement.scrollTop > 700
			})
		}, 100)
	}

	render() {
		return this.state.show && <div className="back-to-top">
			<button onClick={() => { window.scrollTo(0, 0) }}>⬆︎</button>
		</div>
	}
}

export default BackToTop
