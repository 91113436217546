import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import ScrollIndicator from './ScrollIndicator'
import Header from '../components/Header'
import Footer from '../components/Footer'
import BackToTop from '../components/BackToTop'

import './layout.css'

const { Fragment } = React

const Layout = ({ children, categories }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <Fragment>
        <ScrollIndicator />
        <Header
          siteTitle={data.site.siteMetadata.title}
          categories={categories}
        />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 1200,
            minHeight: 700,
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 0,
          }}
        >
          {children}
        </div>
        <Footer />
        <BackToTop />
      </Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
