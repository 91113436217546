import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import './style.css'

class Header extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			currentMenu: 'home'
		}
	}

	componentDidMount() {
		const { pathname } = window.location

		const extract = pathname.split('/')[1]

		this.setState({ currentMenu: extract === '' ? 'home' : extract })

		if (extract === '' || /^[0-9]+$/.test(extract)) {
			this.setState({ currentMenu: 'home' })
		} else if (extract === 'programming') {
			this.setState({ currentMenu: 'programming' })
		} else if (extract === 'conversation') {
			this.setState({ currentMenu: 'conversation' })
		} else if (extract === 'music') {
			this.setState({ currentMenu: 'music' })
		} else if (extract === 'about') {
			this.setState({ currentMenu: 'about' })
		} else {
			this.setState({ currentMenu: this.props.categories || 'null' })
		}
	}

	render() {
		const { siteTitle } = this.props
		const { currentMenu } = this.state

		return <div className="header mobile-menu">
			<div className="container">
				<ul className="mobile-site-title">
					<li><Link to="/">{siteTitle}</Link></li>
				</ul>
				<ul>
					<li className="site-title">
						<h1><Link to="/">{siteTitle}</Link></h1>
					</li>
					<li className={currentMenu === 'home' ? 'menu-item currentMenu' : 'menu-item'}>
						<h3><Link className="mobile-menu" to="/">首页</Link></h3>
					</li>
					<li className={currentMenu === 'programming' ? 'menu-item currentMenu' : 'menu-item'}>
						<h3><Link className="mobile-menu" to="/programming">编程</Link></h3>
					</li>
					<li className={currentMenu === 'conversation' ? 'menu-item currentMenu' : 'menu-item'}>
						<h3><Link className="mobile-menu" to="/conversation">话题</Link></h3>
					</li>
					<li className={currentMenu === 'music' ? 'menu-item currentMenu' : 'menu-item'}>
						<h3><Link className="mobile-menu" to="/music">音乐</Link></h3>
					</li>
					<li className={currentMenu === 'about' ? 'menu-item currentMenu' : 'menu-item'}>
						<h3><Link className="mobile-menu" to="/about">关于</Link></h3>
					</li>
				</ul>
			</div>
		</div>
	}
}

Header.propTypes = {
	siteTitle: PropTypes.string
}

Header.defaultProps = {
	siteTitle: ''
}

export default Header
